'use client'

import { useReportWebVitals } from 'next/web-vitals'
import type { NextWebVital } from 'appsignal/client'
import { reportWebVitals } from 'appsignal/client'
import { usePathname } from 'next/navigation'

export function WebVitals() {
  const pathname = usePathname()
  useReportWebVitals((metric) => {
    reportWebVitals(metric as NextWebVital, pathname)
  })

  return null
}
