import Appsignal from '@appsignal/javascript'
import { useEffect, useRef } from 'react'
import { plugin } from '@appsignal/plugin-window-events'

export { type NextWebVital, reportWebVitals } from './src/webvitals'

const commitSha = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA ?? ''
const commitRef = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF ?? ''
const vercelEnv = process.env.NEXT_PUBLIC_VERCEL_ENV

const frontendPushApiKey =
  process.env.NEXT_PUBLIC_APPSIGNAL_FRONTEND_PUSH_API_KEY

export const appsignal = new Appsignal({
  key: frontendPushApiKey,
  ignoreErrors: [
    /.*ClerkJS: Network error at.*/,
    /^ResizeObserver loop completed with undelivered notifications.*/,
  ],
  revision:
    vercelEnv === 'production'
      ? commitSha
      : // create a version from the branch name
        `${commitRef}-${(commitSha || '').substring(0, 7)}`,
})

export const useErrorReporting = (error: Error, userId: string) => {
  const rendered = useRef(false)

  const sendError = async () => {
    await appsignal.sendError(error, (span) => {
      span.setTags({
        // digest is a unique identifier to correlate the error
        // with logs provided by Next.js
        digest: 'digest' in error ? (error.digest as string) : '',
        user_id: userId || '',
        url: window.location.href,
      })
    })
  }

  useEffect(() => {
    if (!rendered.current) {
      sendError()
        .then(() => (rendered.current = true))
        // eslint-disable-next-line -- log an error if send fails
        .catch(console.error)
    }
  }, [error, userId])
}

/**
 * This plugin from AppSignal will catch any
 * unhandled error in the client.
 *
 * React Error Boundaries are known for not catching async
 * errors, so this should be our last line of defense.
 *
 * @see https://docs.appsignal.com/front-end/plugins/plugin-window-events.html
 */
export const useGlobalErrorReporting = (userId: string | null) => {
  const render = useRef(false)

  useEffect(() => {
    if (!render.current) {
      render.current = true
      appsignal.addDecorator((span) => {
        if (userId) {
          return span.setTags({ user_id: userId })
        }
        return span
      })
      appsignal.use(plugin({}))
    }
  }, [userId])
}
